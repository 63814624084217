import * as React from 'react';
import { connect } from 'react-redux';
import { generateUUID } from '../adapters/utils';
class Usabilla extends React.Component {
    componentDidMount() {
        this.loadUsabilla();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.correlationId !== this.props.correlationId) {
            this.loadUsabilla();
        }
    }

    loadUsabilla() {
        if (window.usabilla_live) {
            const ppaUuid = generateUUID();
            window.usabilla_live('data', {
                'custom': {
                    ppaUuid,
                    correlationId: this.props.correlationId
                }
            });
        } else {
            setTimeout(() => { this.loadUsabilla(); }, 2000);
        }
    }
    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    correlationId: state.welcome.correlationId
});

export default connect(mapStateToProps)(Usabilla);
