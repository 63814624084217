import * as React from 'react';
import { connect } from 'react-redux';
import './loader.css';
import { Spinner } from '../Spinner';
import { TextStyle } from '@able/react';

const LoadingSpinner = (props) => {
    const { payment: { loading: { paymentFieldsInitialised = false } = {} } = {} } = props;

    let classNames = 'div-width hidden';

    if (!paymentFieldsInitialised) {
        classNames = 'div-width';
    }

    return (
        <>
        <div className={classNames}>
            <Spinner className={`${classNames} payment_spinner`} label="Just a moment..." />
            <div className="spinner_message">
                <TextStyle element="p" alias="FinePrintA">Just a moment...</TextStyle>
            </div>
        </div>
        </>
    );
};

export function mapStateToProps(state) {
    return {
        payment: state.payment
    };
}

const ConnectedLoader = connect(mapStateToProps)(LoadingSpinner);

export default ConnectedLoader;
