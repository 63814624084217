import React, { useId } from 'react';

export function CreditCardSvg({ type = '', title }) {
    const titleId = title !== undefined
        ? 'cc-logo-' + useId()
        : undefined;
    let Icon;
    switch (type) {
        case 'visa':
            Icon = Visa;
            break;
        case 'masterCard':
            Icon = MasterCard;
            break;
        default: return null;
    }
    return (
        <svg role="img" height="24" width="24" aria-labelledby={titleId} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {title === undefined ? null : <title id={titleId}>{title}</title>}
            <Icon />
        </svg>
    )
}

const Visa = () => (
    <>
        <defs>
            <path id="id-63a" d="M.424.003h21.515v7.633H.424z"></path>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(1.636 8.542)">
                <mask id="id-64b" fill="#fff">
                    <path d="M.424.003h21.515v7.633H.424z"></path>
                </mask>
                <path d="M18.065 4.9l1.009-2.759.567 2.76h-1.576zM20.403.136h-1.544c-.713 0-.886.55-.886.55L15.11 7.509h2.001l.4-1.095h2.44l.226 1.095h1.762L20.403.135zm-7.834 2.048c0-.834 1.87-.727 2.69-.275l.275-1.583s-.845-.322-1.727-.322c-.953 0-3.215.417-3.215 2.442 0 1.905 2.656 1.928 2.656 2.929 0 1-2.382.821-3.168.19L9.794 7.22s.857.417 2.167.417c1.31 0 3.287-.68 3.287-2.525 0-1.917-2.679-2.096-2.679-2.93zM5.076.135l-1.83 5.072-.216-1.092-.048-.245a6.122 6.122 0 0 0-1.703-2.159 7.696 7.696 0 0 0-.855-.59l1.664 6.388h2L7.142.135H5.076zm2.815 0l-1.2 7.374h1.92l1.2-7.374h-1.92z" fill="#1A1F71"></path>
            </g>
            <path d="M4.046 9.372s-.08-.666-.928-.666H.036L0 8.831s1.481.302 2.903 1.434c1.358 1.081 1.801 2.43 1.801 2.43l-.658-3.323z" fill="#1A1F71"></path>
        </g>
    </>
);

const MasterCard = () => (
    <>
        <g fill="none" fillRule="evenodd">
            <path
                d="M9.062 7.436h5.777v9.11H9.062z"
                fill="#FF5F00"></path>
            <path
                d="M9.657 11.992c-.002-1.777.843-3.457 2.291-4.554-2.46-1.867-5.992-1.595-8.115.625a5.647 5.647 0 0 0 0 7.86c2.123 2.22 5.655 2.491 8.115.625-1.448-1.098-2.293-2.778-2.291-4.556z"
                fill="#EB001B">
            </path>
            <path
                d="M21.657 11.992c0 2.218-1.312 4.241-3.378 5.21a6.176 6.176 0 0 1-6.33-.656c1.447-1.098 2.292-2.777 2.292-4.555 0-1.777-.845-3.456-2.293-4.555a6.176 6.176 0 0 1 6.33-.656c2.067.969 3.379 2.992 3.379 5.21v.002z"
                fill="#F79E1B">
            </path>
        </g>
    </>
);
